var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "videowrapper" }, [
    _c("div", { staticClass: "media-video-text-block h-100 w-100" }, [
      _c(
        "div",
        { staticClass: "w-100 h-100 div_general" },
        [
          _c(
            "b-row",
            {
              staticClass:
                "w-100 h-100 d-flex position-relative offre_special_container",
            },
            [
              _c("b-col", { staticClass: "h-25 mt-2", attrs: { cols: "8" } }, [
                _vm.contenu.petiteNote
                  ? _c("div", { staticClass: "boite_petite_note" }, [
                      _c("span", {
                        staticClass: "petite_note graphik_bold",
                        domProps: { innerHTML: _vm._s(_vm.contenu.petiteNote) },
                      }),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c(
                "b-col",
                {
                  staticClass:
                    "d-flex flex-column align-items-center align-items-md-start py-1 py-md-0 justify-content-center position-static col-12 col-md-4 barreDessousMobile mobile_text_center",
                  style: _vm.styleObject,
                },
                [
                  _c("div", [
                    _vm.contenu.surTitre
                      ? _c("div", {
                          staticClass: "offre_surtitre graphik_bold",
                          domProps: { innerHTML: _vm._s(_vm.contenu.surTitre) },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("h3", {
                      staticClass: "titre graphik_bold",
                      domProps: { innerHTML: _vm._s(_vm.contenu.titre) },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "sousTitre",
                    domProps: { innerHTML: _vm._s(_vm.contenu.sousTitre) },
                  }),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "stretched-link offre-speciale",
                      attrs: {
                        id: _vm.contenu["@id"],
                        href: _vm.getMediaImageUrl(_vm.contenu),
                        "ga-img-name": _vm.imageName,
                        "data-tag":
                          _vm.getNomRouteCourante() +
                          " - " +
                          _vm.contenu.titre.replace(/&nbsp;/g, " ") +
                          " - " +
                          _vm.contenu.sousTitre +
                          " - " +
                          _vm.contenu.dataTag,
                      },
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "videobtn btn graphik_bold",
                          style: [
                            { "border-color": _vm.contenu.borderColorBtn },
                            { "background-color": _vm.contenu.colorBtn },
                          ],
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.contenu.texteBouton) +
                              "\n            "
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "yt-ambiance-video" }, [
      _c(
        "div",
        {
          staticClass:
            "content-inner youtube-container embed-responsive embed-responsive-16by9",
          style: { "background-image": "url(' {{ contenu.bgImage }} ');" },
        },
        [
          _c("iframe", {
            staticClass: "iframe_video",
            attrs: {
              title: "YouTube video player",
              width: _vm.largeur,
              height: "380",
              "data-src": _vm.lienYoutube,
              type: "text/html",
              frameborder: "0",
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "video-consent-overlay" }, [
            _c("img", {
              staticClass: "bg-consent h-100",
              class: _vm.contenu.bgImage == "" ? "d-none" : "",
              attrs: {
                src: "${contenu.bgImage!}",
                alt: "image de fond vidéo youtube",
              },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "scrollable-section" }, [
              _vm._m(0),
              _vm._v(" "),
              _c("div", { staticClass: "video-consent-overlay-titre" }, [
                _c("h3", [_vm._v(_vm._s(_vm.$t("youtubeVideoConsent.titre")))]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "video-consent-overlay-text" }, [
                _c("p", [_vm._v(_vm._s(_vm.$t("youtubeVideoConsent.texte")))]),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "button",
              { staticClass: "video-consent-overlay-accept-button" },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("youtubeVideoConsent.texteBoutonAccept")) +
                    "\n        "
                ),
              ]
            ),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "logo" }, [
      _c("img", {
        staticClass: "video-consent-overlay-logo",
        attrs: {
          src: "/dam/espacejeux/logo/logo-youtube-consent.svg",
          alt: "logo youtube",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }