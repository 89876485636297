var URL_PORTAIL_def = function () {
  this.IDENTIFIANT_GOOGLE_ANALYTICS = "UA-26901796-1";
  this.IDENTIFIANT_GOOGLE_ANALYTICS_2 = "UA-26901796-2";
  this.IDENTIFIANT_GOOGLE_ANALYTICS_3 = "";
  this.DOMAINE_GOOGLE_ANALYTICS = ".espacejeux.com";

  this.PROMO_DEST = {
    // Trié ALPHABETIQUEMENT
    // Note: Destination webview -> voir configuration du controlleur
    // Ne pas oublier la correspondance avec la section "type" qui suit

    fr: {
      casino: "/fr/casino",
      depot: "/fr/mon-compte/depot",
      "depot-credit": "/fr/mon-compte/depot?onglet=credit-tab",
      "depot-interac": "/fr/mon-compte/depot?onglet=interac-tab",
      "depot-argentWeb": "/fr/mon-compte/depot?onglet=argentWeb-tab",
      "depot-facture": "/fr/mon-compte/depot?onglet=facture-tab",
      detail: "/fr/mon-compte/details-du-compte",
      "club-casino-privilege": "/fr/compte/club-casino-privileges",
      autoexclusion: "/fr/mon-compte/autoexclusion",
      faq: "/fr/faq",
      historique: "/fr/mon-compte/historique-compte",
      inscription: "/fr/inscription/bienvenue",
      jeu: "/fr/casino/p/machines-a-sous",
      jeuaidereference: "https://aidejeu.ca/",
      jeuresponsable: "/fr/jeu-responsable",
      "lel-abonnements": "/lel/fr/mes-abonnements",
      "lel-achats": "/lel/fr/mes-achats",
      "lel-preferences-courriel": "/lel/fr/avis-par-courriel",
      "lel-favoris": "/lel/fr/mes-favoris",
      "lel-groupes": "/lel/fr/mes-groupes",
      "lel-jeux": "/fr/jeux",
      "limites-de-jeu": "/fr/mon-compte/limites-de-jeu",
      "mon-compte": "/fr/mon-compte/accueil",
      nousjoindre: "/fr/pour-nous-joindre",
      "parties-en-cours": "/fr/mon-compte/parties-en-cours",
      "pauses-de-jeu": "/fr/mon-compte/pauses-de-jeu",
      poker: "/fr/ok-poker",
      "poker-challenges": "/fr/ok-poker/tournois/challenge",
      "poker-evenements": "/fr/ok-poker/evenements",
      "poker-ilfautsavoir": "/fr/ok-poker/campus/il-faut-savoir",
      "poker-lexique": "/fr/ok-poker/comment-jouer/lexique-et-abreviations",
      "poker-pseudo": "/fr/ok-poker/pseudo",
      "poker-raccourcis": "/fr/ok-poker/comment-jouer/raccourcis-clavier",
      "poker-regles": "/fr/ok-poker/comment-jouer/jeux",
      promo: "/fr/mon-compte/promotions",
      retrait: "/fr/mon-compte/retraits",
      "securite-et-mot-de-passe": "/fr/compte/securite-et-mot-de-passe",
      "validation-identite": "/fr/compte/identite-reprise",
      "club-casino-privileges": "/fr/compte/club-casino-privileges",
    },
    en: {
      casino: "/en/casino",
      depot: "/en/my-account/deposit",
      "depot-credit": "/en/my-account/deposit?onglet=credit-tab",
      "depot-interac": "/en/my-account/deposit?onglet=interac-tab",
      "depot-argentWeb": "/en/my-account/deposit?onglet=argentWeb-tab",
      "depot-facture": "/en/my-account/deposit?onglet=facture-tab",
      detail: "/en/my-account/change-details",
      "club-casino-privilege": "/en/account/casino-privileges-club",
      autoexclusion: "/en/my-account/self-exclusion",
      faq: "/en/faq",
      historique: "/en/my-account/account-history",
      inscription: "/en/registration/welcome",
      jeu: "/en/casino/p/slot-machines",
      jeuaidereference: "https://aidejeu.ca/en/",
      jeuresponsable: "/en/responsible-gambling",
      "lel-abonnements": "/lel/en/my-subscriptions",
      "lel-achats": "/lel/en/my-purchases",
      "lel-preferences-courriel": "/lel/en/e-mail-notices",
      "lel-favoris": "/lel/en/my-favourites",
      "lel-groupes": "/lel/en/my-groups",
      "lel-jeux": "/en/games",
      "limites-de-jeu": "/en/my-account/gaming-limits",
      "mon-compte": "/en/my-account/home",
      nousjoindre: "/en/contact-us",
      "parties-en-cours": "/en/my-account/games-in-progress",
      "pauses-de-jeu": "/en/my-account/pauses",
      poker: "/en/ok-poker",
      "poker-challenges": "/en/ok-poker/tournaments/challenge",
      "poker-evenements": "/en/ok-poker/events",
      "poker-ilfautsavoir": "/en/ok-poker/campus/need-to-know",
      "poker-lexique": "/en/ok-poker/how-to-play/lexicon-and-abbreviations",
      "poker-pseudo": "/en/ok-poker/pseudo",
      "poker-raccourcis": "/en/ok-poker/how-to-play/keyboard-shortcuts",
      "poker-regles": "/en/ok-poker/how-to-play/games",
      promo: "/en/my-account/promotions",
      retrait: "/en/my-account/withdrawals",
      "securite-et-mot-de-passe": "/en/account/security-and-password",
      "validation-identite": "/en/account/identity-retry",
      "casino-privileges-club": "/en/account/casino-privileges-club",
    },

    // La section "type" doit avoir une correspondance parfaite avec ce qui précède
    // 0	Pas d'authentification,
    // 1	Authentification nécessaire,
    // 2	Authentification si possible
    //
    // Il n'y a que "inscription" qu'on force un type "non-connexion"

    type: {
      casino: 2,
      depot: 1,
      "depot-credit": 1,
      "depot-interac": 1,
      "depot-argentWeb": 1,
      "depot-facture": 1,
      detail: 1,
      "club-casino-privilege": 1,
      autoexclusion: 1,
      faq: 2,
      historique: 1,
      inscription: 0,
      jeu: 1,
      "jeu-ate": 1,
      jeuaidereference: 2,
      jeuresponsable: 2,
      "lel-abonnements": 1,
      "lel-achats": 1,
      "lel-preferences-courriel": 1,
      "lel-favoris": 1,
      "lel-groupes": 1,
      "lel-jeux": 1,
      "limites-de-jeu": 1,
      "mon-compte": 1,
      nousjoindre: 2,
      "parties-en-cours": 1,
      "pauses-de-jeu": 1,
      poker: 2,
      "poker-challenges": 2,
      "poker-evenements": 2,
      "poker-ilfautsavoir": 2,
      "poker-lexique": 2,
      "poker-pseudo": 1,
      "poker-raccourcis": 2,
      "poker-regles": 2,
      "poker-webview-evenements": 2,
      "poker-webview-info": 2,
      "poker-webview-regles": 2,
      promo: 1,
      retrait: 1,
      "securite-et-mot-de-passe": 1,
      "validation-identite": 1,
    },
  };
};

URL_PORTAIL = new URL_PORTAIL_def();

//FIX pour retirer l'adresse de courriel de la pastille d'info dans le formulaire d'inscription
//(meilleur endroit pour que ça fonctionne autant desktop que mobile et loteries que espacejeux).
$(document).ready(function () {
  $("#aide-container #aide div:nth-child(2), #aide-container #aide div:nth-child(3)").remove();
  $("#sm-aide-container #sm-aide #collapse-aide .panel-body .row .col-xs-7").remove();
});

(function () {
  $(document).on("click", "#footer .link-lists button", function (e) {
    $(this).toggleClass("active");
    $(this).next().slideToggle();
  });
  $(document).on("click", "#Footer_fr .link-lists button", function (e) {
    $(this).toggleClass("active");
    $(this).next().slideToggle();
  });
  $(document).on("click", "#Footer_en .link-lists button", function (e) {
    $(this).toggleClass("active");
    $(this).next().slideToggle();
  });
})();
